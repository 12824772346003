<template>
    <Modal
        id="dialog-unit-links-create"
        ref="modal"
        :remove-main-slot-content-when-invisible="true"
        :show-close-button="false"
        :title="trans('modals.unit_links_create.title')"
        :user-closable="true"
        event-type="MODAL_UNIT_LINKS_CREATE"
    >
        <div class="url">
            <TextInput
                :model="form"
                :placeholder="trans('modals.unit_links_create.placeholders.url')"
                :read-only="true"
                class="no-wrap"
                icon="icon_copy"
                property="url"
            />
            <ButtonCircular
                v-tooltip="'labels.copy'"
                class="small"
                disabled="disabled"
                icon="icon_copy"
            />
        </div>

        <TextInput
            :label="trans('modals.unit_links_create.labels.name')"
            :maxlength="50"
            :model="form"
            :placeholder="trans('modals.unit_links_create.placeholders.name')"
            class="no-wrap"
            property="name"
        />

        <div :class="{ 'is-invalid' : errors?.valid_from }" class="date-picker-wrapper has-label no-wrap start-date">
            <label>{{ trans('modals.unit_links_create.labels.start_date') }}</label>
            <input
                ref="startDate"
                v-model="form.valid_from"
                v-tooltip="startDateTooltip"
                name="valid_from"
                type="datetime-local"
            >
        </div>
        <div v-if="errors?.valid_from" class="invalid-feedback" role="alert">
            <strong>{{ errors.valid_from }}</strong>
        </div>

        <div
            :class="{ 'checkbox-disabled' : !form.enable_end_date, 'is-invalid' : errors?.expires_at }"
            class="date-picker-wrapper has-label no-wrap">
            <Checkbox
                :model="form"
                class="no-wrap"
                layout="checkbox-left"
                property="enable_end_date"
                @click.stop
            />
            <label>{{ trans('modals.unit_links_create.labels.end_date') }}</label>
            <input
                ref="endDate"
                v-model="form.expires_at"
                v-tooltip="endDateTooltip"
                :disabled="!form.enable_end_date"
                class="end-date"
                name="expires_at"
                type="datetime-local"
                @change="validate"
            >
        </div>
        <div v-if="errors?.expires_at" class="invalid-feedback" role="alert">
            <strong>{{ errors.expires_at }}</strong>
        </div>

        <template #buttons>
            <ButtonSecondary
                :disabled="isSaving"
                caption="modals.unit_links_create.buttons.close"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                :disabled="hasErrors || isSaving"
                caption="modals.unit_links_create.buttons.save"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import Modal from '@/Vue/Modals/Modal.vue';
import Unit from '@/Models/Unit/Unit';
import {defineComponent, inject} from 'vue';
import {trans} from '@/Utility/Helpers';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import {unitLinkServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import TextInput from '@/Vue/Common/TextInput.vue';
import moment from 'moment-timezone';
import Checkbox from '@/Vue/Common/Checkbox.vue';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import {cloneDeep, debounce} from 'lodash';

export default defineComponent({
    components: {
        ButtonCircular,
        Checkbox,
        TextInput,
        ButtonPrimary,
        ButtonSecondary,
        Modal
    },

    props: {
        unit: {
            type: Unit,
            required: true,
        },
    },

    data() {
        return {
            unitLinkService: inject(unitLinkServiceKey)!,
            defaults: {
                form: {
                    name: null,
                    valid_from: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
                    enable_end_date: false,
                    expires_at: moment().add(30, 'days').endOf('day').format('YYYY-MM-DDTHH:mm'),
                }
            },

            form: {
                name: null,
                valid_from: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
                enable_end_date: false,
                expires_at: moment().add(30, 'days').endOf('day').format('YYYY-MM-DDTHH:mm'),
            },

            errors: {} as any,
        };
    },

    computed: {

        startDateTooltip() {
            return moment(this.form.valid_from).isValid() ? moment(this.form.valid_from).toISOString() : null;
        },

        endDateTooltip() {
            return this.form.enable_end_date && moment(this.form.expires_at).isValid() ?
                moment(this.form.expires_at).toISOString() :
                null;
        },

        unitLinks() {
            return this.unit?.unit_links ?? [];
        },

        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        },

        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },

        isSaving() {
            return this.unitLinkService.isSaving;
        },
    },

    watch: {
        'form.expires_at': function() {
            this.validate();
        },
        'form.valid_from': function() {
            this.validate();
        },
        'form.enable_end_date': function() {
            this.validate();
        }
    },

    mounted() {
        this.reset();
    },

    methods: {
        trans,

        validateDateTime(name: string) {
            delete this.errors[name];

            const selectedDateTime = moment(this.form[name], moment.ISO_8601, true);

            if (!selectedDateTime.isValid()) {
                this.errors[name] = trans('modals.unit_links_create.errors.invalid_date');
                return;
            }

        },

        validate() {
            this.errors = new Object({});

            this.validateDateTime('valid_from');

            if (this.form.enable_end_date) {
                this.validateDateTime('expires_at');
            }
        },

        createTenantUnitLink() {

            this.validate();

            if (this.hasErrors) {
                return;
            }

            let userTimezone = moment.tz.guess();

            let unitLinkData = {
                tenant_uid: window.currentUser!.tenant!.uid,
                unit_uid: this.unit.uid,
                name: this.form.name !== '' ? this.form.name : null,
                expires_at: this.form.enable_end_date && this.form.expires_at !== '' ?
                    moment.tz(this.form.expires_at, userTimezone).utc().toISOString() :
                    null,
                valid_from: moment.tz(this.form.valid_from, userTimezone).utc().toISOString(),
            };

            this.unitLinkService
                .createUnitLink(
                    unitLinkData
                )
                .then(unitLink => {
                    this.unit.unit_links.push(unitLink);
                    this.reset();
                    this.modal.hide();
                })
                .catch(this.onErrorApi);
        },

        reset() {
            this.form = cloneDeep(this.defaults.form);
            this.errors = new Object({});
        },

        onClickCancel() {
            this.reset();
            this.modal.cancel();
        },

        onClickApply: debounce(function(this: any) {
            this.createTenantUnitLink();
        }, 500),

        /**
         * Error handler for API errors
         *
         * @param {String} error
         */
        onErrorApi(error: string) {
            this.$root!.showErrorDialog(error);
            return this;
        },

    }
});
</script>

<style scoped>
#dialog-unit-links-create {
    width: 450px;

    & > .buttons {
        .btn.btn-primary,
        .btn.btn-secondary {
            flex-grow: 0;
        }
    }
}

.url {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: var(--forminput-spacing);

    .btn {
        flex-grow: 0;
        margin-bottom: var(--forminput-spacing);
    }

    .textinput {
        flex-grow: 1;
    }
}


.date-picker-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    margin-bottom: var(--forminput-spacing);

    .checkbox {
        margin-bottom: 0;
    }

    label {
        flex-grow: 1;
    }

    input[type="datetime-local"] {
        flex-basis: 200px;
    }

    &.is-invalid {
        margin-bottom: 0;

        input[type="datetime-local"] {
            border-color: #dc3545;
        }
    }

    input.end-date {
        align-self: end;
    }

    &.checkbox-disabled {
        label {
            color: var(--color-anthracite40);
        }

        input {
            color: var(--color-anthracite40);
        }
    }
}

.date-picker-wrapper ~ .invalid-feedback {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: var(--forminput-spacing);
    color: #dc3545;

    strong {
        flex-basis: 200px;
    }
}

.start-date.date-picker-wrapper {
    justify-content: space-between;
}

</style>
