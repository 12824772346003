<template>
    <tr
        :key="'unit-link-key-'+unitLink.uid"
        class="unit-links-table-row"
    >
        <td class="name">
            <ButtonCircular
                v-tooltip="'labels.copy'"
                class="small"
                icon="icon_copy"
                @trigger="onUnitLinkCopy(unitLink, $event)"
            />
            <span v-tooltip="unitLink.url">{{ unitLink.name ?? unitLink.url }}</span>
        </td>
        <td v-tooltip="startDateTooltip" class="start-date">
            {{ formatDate(unitLink.valid_from) }}
        </td>
        <td v-tooltip="endDateTooltip" class="end-date">
            {{ formatDate(unitLink.expires_at) }}
        </td>
        <td class="publisher">
            {{ unitLink?.owner?.fullName }}
        </td>
        <td class="actions">
            <ButtonCircular
                v-tooltip="'labels.edit'"
                :disabled="!canEdit"
                class="small"
                icon="icon_edit"
                @trigger="onUnitLinkEdit"
            />
            <ButtonCircular
                v-tooltip="'labels.remove'"
                :disabled="!canDelete"
                class="small"
                icon="icon_delete"
                @trigger="onUnitLinkRemove"
            />
        </td>
    </tr>
</template>

<script lang="ts">
import moment from 'moment';
import {Permission} from '@/Models/User/Permission';
import {trans} from '@/Utility/Helpers';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import {defineComponent} from 'vue';
import UnitLink from '@/Models/Unit/UnitLink';
import {Clipboard} from '@/Utility/Clipboard';

export default defineComponent({
    components: {
        ButtonCircular,
    },

    props: {
        unitLink: {
            type: UnitLink,
            required: true,
        },
    },

    emits: {
        'click-delete': (_: UnitLink) => true,
        'click-edit': (_: UnitLink) => true,
    },

    data() {
        return {
            isRemoveConfirmDialogVisible: false,
        };
    },

    computed: {
        startDateTooltip() {
            return moment(this.unitLink.valid_from).isValid() ? moment(this.unitLink.valid_from).toISOString() : null;
        },

        endDateTooltip() {
            return moment(this.unitLink.expires_at).isValid() ? moment(this.unitLink.expires_at).toISOString() : null;
        },

        canEdit() {
            return this.$gate.allows(Permission.ability(Permission.UnitLinksUpdate()), this.unitLink);
        },

        canDelete() {
            return this.$gate.allows(Permission.ability(Permission.UnitLinksDelete()), this.unitLink);
        },
    },

    methods: {
        formatDate(date: Date | null) {
            if (date === null) {
                return 'None';
            }

            let userTimezone = moment.tz.guess();

            return moment.utc(date).tz(userTimezone).format(trans('modals.unit_links.date_format'));
        },

        onUnitLinkCopy(unitLink: UnitLink, e: Event) {
            (e.target as HTMLButtonElement)?.blur();

            if (unitLink.url === null) {
                return;
            }

            Clipboard.writeString(unitLink.url);
        },

        onUnitLinkRemove(e: Event) {
            (e.target as HTMLButtonElement)?.blur();
            this.$emit('click-delete', this.unitLink);
        },

        onUnitLinkEdit(e: Event) {
            (e.target as HTMLButtonElement)?.blur();
            this.$emit('click-edit', this.unitLink);
        },
    }
});
</script>

<style lang="scss" scoped>

</style>
