<template>
    <div class="unit-preview-overlay">
        <h2>{{ title }}</h2>
        <p v-if="description">
            {{ description }}
        </p>
        <ButtonPrimary
            :caption="buttonCaption"
            :class="{'icon-only': buttonCaption === null }"
            :disabled="buttonDisabled"
            :icon="buttonIcon"
            @trigger="onClick"
        />
    </div>
</template>

<script lang="ts">

import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import type {PropType} from 'vue';
import {defineComponent} from 'vue';

export default defineComponent({
    components: {
        ButtonPrimary,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String as PropType<string | null>,
            required: true,
        },
        buttonCaption: {
            type: String as PropType<string | null>,
            required: true,
        },
        buttonIcon: {
            type: String as PropType<string | null>,
            required: true,
        },
        buttonDisabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    emits: [
        'click'
    ],

    methods: {
        onClick() {
            this.$emit('click');
        },
    },
});
</script>

<style scoped>
.unit-preview-overlay {
    flex-basis: 500px;
    display: flex;
    flex-direction: column;
    border: none;
    padding: 32px;
    background-color: var(--background-color-white);
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-box-shadow);
    z-index: 1;

    p {
        margin-bottom: 0;
    }

    h2 + button {
        margin-top: 16px;
    }

    .btn {
        width: 100%;
        min-height: 35px;
    }

    &:deep(.btn.icon-only svg.icon) {
        margin-right: 0;
    }
}

</style>
