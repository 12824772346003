<template>
    <div v-if="canUseUnitLinks" class="property property-unit-links">
        <header>Public Links</header>
        <div class="unit-link-buttons">
            <ButtonSecondary
                caption="labels.share"
                icon="icon_share"
                @trigger="showUnitLinks"
            />
        </div>

        <ModalUnitLinks :unit="unit" />
        <ModalUnitLinksCreate
            :unit="unit"
        />
        <ModalUnitLinksEdit
            :unit="unit"
        />

        <ModalUnitLinksDelete />

    </div>
</template>

<script lang="ts">

import {defineComponent, inject} from 'vue';
import {trans} from '@/Utility/Helpers/trans';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import Unit from '@/Models/Unit/Unit';
import {Permission} from '@/Models/User/Permission';
import ModalUnitLinks from '@/Vue/Modals/UnitLinks/ModalUnitLinks.vue';
import EventType from '@/Utility/EventType';
import ModalUnitLinksCreate from '@/Vue/Modals/UnitLinks/ModalUnitLinksCreate.vue';
import ModalUnitLinksEdit from '@/Vue/Modals/UnitLinks/ModalUnitLinksEdit.vue';
import ModalUnitLinksDelete from '@/Vue/Modals/UnitLinks/ModalUnitLinksDelete.vue';
import {Feature} from '@/Models/Features/Feature';
import {featureRepositoryKey} from '@/Vue/Bootstrap/InjectionKeys';

export default defineComponent({
    name: "PanelUnitLinks",

    components: {
        ModalUnitLinksDelete,
        ModalUnitLinksEdit,
        ModalUnitLinksCreate,
        ModalUnitLinks,
        ButtonSecondary,
    },

    props: {
        unit: {
            type: Unit,
            required: true,
        },
    },

    data() {
        return {
            featureRepository: inject(featureRepositoryKey)!,
        }
    },

    computed: {
        canUseUnitLinks() {
            return this.$gate.allows(Permission.UnitLinksRead())
                && this.featureRepository.active(Feature.EntitlementPublicUnits);
        },
    },

    methods: {
        trans,

        showUnitLinks() {
            this.$globalEvents.emit(EventType.MODAL_UNIT_LINKS_SHOW);
        },
    },
});
</script>

<style scoped lang="scss">

    #layout-inspector {
        .property-unit-links {
            padding-bottom: 10px;
            margin-bottom: 6px;
        }
    }

    .unit-link-buttons {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        gap: 15px;

        .btn {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
        }
    }
</style>
