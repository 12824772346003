<template>
    <Modal
        ref="modal"
        :event-type="eventType"
        :remove-main-slot-content-when-invisible="true"
        :show-close-button="false"
        :title="trans('modals.unit_links_delete.title')"
        :user-closable="true"
        @show="onShow"
    >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="description" v-html="description" />

        <template #buttons>
            <ButtonSecondary
                :disabled="isBusy"
                caption="modals.unit_links_delete.buttons.close"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                :disabled="isBusy"
                caption="modals.unit_links_delete.buttons.apply"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import {trans} from '@/Utility/Helpers';
import type UnitLink from '@/Models/Unit/UnitLink';
import moment from 'moment';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import {unitLinkServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import EventType from '@/Utility/EventType';
import Modal from '@/Vue/Modals/Modal.vue';
import {debounce} from 'lodash';

export default defineComponent({
    components: {
        Modal,
        ButtonPrimary,
        ButtonSecondary,
    },

    data() {
        return {
            unitLinkService: inject(unitLinkServiceKey)!,
            description: null as string | null,
            eventType: 'MODAL_UNIT_LINKS_DELETE',
            model: null as UnitLink | null,
        };
    },

    computed: {
        isBusy() {
            return this.unitLinkService.isSaving || this.unitLinkService.isLoading || this.unitLinkService.isDeleting;
        },
        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        },
    },

    methods: {
        trans,

        updateDescription(unitLink: UnitLink) {
            let userTimezone = moment.tz.guess();
            this.model = unitLink;
            let lines = new Array<string>;

            lines.push(trans('modals.unit_links_delete.description.text'));

            if (unitLink.name !== null) {
                lines.push(trans('modals.unit_links_delete.description.name', { name: unitLink.name }));
            }

            lines.push(trans('modals.unit_links_delete.description.url', { url: unitLink.url! }, false));
            lines.push(trans(
                'modals.unit_links_delete.description.start_date',
                { start_date: moment.utc(unitLink.valid_from).tz(userTimezone).format('DD MMM YYYY HH:mm') }
            ));

            if (unitLink.expires_at !== null) {
                lines.push(trans(
                    'modals.unit_links_delete.description.end_date',
                    { end_date: moment.utc(unitLink.expires_at).tz(userTimezone).format('DD MMM YYYY HH:mm') }
                ));
            }

            this.description = lines.join('<br>');
        },

        onShow(unitLink: UnitLink) {
            this.updateDescription(unitLink);
        },

        onClickCancel() {
            this.modal.cancel();
        },

        onClickApply: debounce(function(this: any) {
            this.$globalEvents.emit(EventType[this.eventType + '_APPLY'], this.model);
        }, 500),
    }
});
</script>

<style lang="css" scoped>

</style>
